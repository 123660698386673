.webhooks-label{
    padding: 5px 25px;
    border-radius: 20px;
    color: #073d83;
    font-weight: 600;
    border: 2px solid #073d83;
    display: flex;
    align-items: center;
    justify-content: center;
    h4{
        margin-bottom: 0;
    }
}
.webhooks-label-fill{
    padding: 5px 25px;
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
    background-color: #073d83;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.webhooks-label-outline{
    padding: 5px 25px;
    border: 2px solid #073d83;
    border-radius: 20px;
    background-color: #2f6dfd2f;
    font-weight: 600;
    color: #073d83;
}
.webhooks-event{
    max-height: 500px;
    overflow: auto;
}