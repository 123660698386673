@import "../../common/variables";
.my-block-chain-wrapper {
  .wrapper-block {
    flex-wrap: wrap;
    height: 289px;
    overflow-y: auto;
    li {
      padding: 10px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      .first-title {
        margin-bottom: 3px;
      }
      border-bottom: 1px solid $sixth;
      &:hover {
        background-color: #f5f6f8;
      }
    }

    &.peer-management {
      li {
        .block-2 {
          display: inline-flex;
          .angle-block {
            padding: 5px;
            margin-left: 10px;
          }
        }
      }
      &.order-management {
        height: 330px;
      }
    }
  }
} /* No CSS */ /*# sourceMappingURL=myblockchain.css.map */

.right-block {
  width: 306px;
  background-color: $fifth;
  border-radius: 10px;
  .flex-bl {
    height: 100%;
    padding: 20px 30px;
  }
}

.loading {
  color: #969799;
  text-align: center;
  //   padding-top: 5%;
}

.dots {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
