.extra-css {
  width: max-content;
}

.peer-dropdown {
  border: 1px solid black;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
}

.dropdown-container {
  border: 1px solid black;
  width: 100%;
  position: relative;
  cursor: pointer;
  z-index: 3;
}

.peer-dropdown p {
  text-align: initial;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container p {
  text-align: initial;
}

.dropdown-container div {
  width: 100%;
  padding: 0;
  border-width: 1px;
  height: 18px;
  padding-left: 5px;
  box-sizing: border-box;
}

.option {
  display: flex;
  align-items: center;
  background-color: white;
  width: max-content;
  // width:fit-content;
}

.option:hover {
  background-color: lightskyblue;
}

.load-diff-div {
  // height: 411px;
  p {
    color: #969799;
    text-align: center;
  }
}
.load-diff {
  width: 30%;
  text-align: center;
    cursor: pointer;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  // Loader code

  .loading {
    width: 350px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 100%;
  }

  .loading span {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #073d83;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
    display: inline-block;
  }

  .loader-center {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 55%;
    margin-left: 60%;
  }
  .activity-log-loader {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 150%;
    margin-top: 75%;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .blur-graph {
    filter: blur(1.5px);
  }

  .recharts-wrapper {
    height: 420px !important;
  }


.load-diff {
  width: 30%;
  text-align: center;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
}

// Loader code

.loading {
  width: 350px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 100%;
}

.loading span {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #073d83;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.loader-center {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55%;
  margin-left: 60%;
}

.loader-center-metric-monitoring{
  width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55%;
  margin-left: 60%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blur-graph {
  filter: blur(1.5px);
}

.recharts-wrapper {
  height: 420px !important;
}

.peer-drop-down {
  ::-webkit-scrollbar {
    display: none;
  }
}