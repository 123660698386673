/* Hero */
.hero {
  text-align: center;
  padding: 5rem 1rem 16rem;
  padding-top: 1rem;
  margin: auto;
  color: #2c374b;
  font-family: 'silka', Arial, Helvetica, sans-serif;
  background: white;
}
.hero img {
  max-width: 170px;
}
.hero a {
  color: inherit;
}
.hero h1 {
  font-size: 3.5rem;
  line-height: 1.25;
  padding: 2rem 0;
  margin: 0;
  font-weight: 500;
  color: transparent;
  background: linear-gradient(-60deg, #4e5de4, #49a5fa, #51d4ff, #49a5fa, #51d4ff);
  background-size: 200% 200%;
  animation: Gradient 8s ease infinite;
  -moz-animation: Gradient 8s ease infinite;
  -webkit-animation: Gradient 8s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero p {
  margin: 0 auto;
  font-size: 1.3em;
  line-height: 1.5;
  color: #798294;
  font-weight: 400;
  max-width: 40rem;
}
.underline-text{
  text-decoration: underline !important;
}

/* Wizard */
.wizard-container {
  padding: 0 1rem 3rem;
  max-width: 100%;
  margin: -12rem auto 0;
}
.wizard {
    background-color: #f9fafb;
    box-shadow: 0px 10px 40px rgb(108 133 171 / 20%);
    border-radius: 1rem;
    min-width: 800px;
    max-width: 1100px;
    margin: auto;
}

/* FAQ */
.faq {
  max-width: 45rem;
  margin: 0 auto 2rem;
  padding: 0 2rem;
}
.faq ul {
  padding-inline-start: 0;
}
.faq li {
  padding: 1rem 0;
  border-bottom: solid 1px #e1e1e2;
  list-style: none;
}
.faq li:last-child {
  border: none;
}
.faq h3 {
  color: #3d4a5c;
  font-family: 'silka', Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
}
.faq p {
  color: #818998;
  margin-top: 0.5rem;
}

/* Defender */
.defender {
  max-width: 45rem;
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #4e5de4 100%);
  box-shadow: 0px 10px 30px rgba(181, 185, 192, 0.3);
  border-radius: 1rem;
  padding: 3rem 6rem;
  margin: 0 auto 4rem;
  color: white;
  text-align: center;
  line-height: 1.5;
}
.defender p {
  margin: 0 auto 2rem;
  font-size: 1.2rem;
  max-width: 45rem;
  color: #f0f6fe;
}
.defender a {
  display: block;
  background: white;
  font-size: 1rem;
  line-height: 1;
  color: #3586f0;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  width: fit-content;
  margin: auto;
}
.defender a:hover,
.defender a:focus {
  box-shadow: 0px 0px 12px rgb(255 255 255 / 80%);
  outline: none;
  border: none;
}

/* Footer */
.footer-icons {
  display: flex;
  justify-content: center;
}
footer img {
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
}
footer p {
  text-align: center;
  color: #757588;
  font-family: 'silka', Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  margin: 0 auto 2rem;
  padding: 0 1rem;
}
footer a {
  color: #757588;
  font-family: 'silka', Arial, Helvetica, sans-serif;
  text-decoration: none;
}
footer a:hover,
footer a:focus {
  color: #4e5de4;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .defender {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 3rem;
    text-align: left;
  }
  .defender a {
    margin: 0 auto 0 0;
    width: max-content;
  }
}
