@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
$primary: #1e2835;
$secondary: #073d83;
$primary_Font: "Lato", sans-serif;

$third: #1e2835;
$forth: #007de4;
$fifth: #e8f0fe;
$sixth: #dadce0;
$seventh: #16202d;
$eight: #1673b1;
$ninth: #868686;
$tenth: #7a7a7a;
$eleven: #0d0f12;
$white: #ffffff;
$green: #85d996;
//$third: #4c4f53;
