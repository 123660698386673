.background-block {
  background: radial-gradient(68.05% 68.05% at 50% 50%,
      #005397f2 0%,
      #000000f2 100%);
  width: 100vw;
  height: 100vh;
}

.div-block-90 {
  height: auto;
  min-height: 100px;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: end;
  justify-content: space-around;
  align-items: stretch;
  justify-items: start;
  display: flex;
}

.div-swap-header {
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 50px 50px 0;
}

.swap-container {
  width: 100%;
  max-width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-self: auto;
  align-items: stretch;
  display: flex;
}

.image-edx-logo {
  height: 3.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.div-block-87 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  font-size: 20px;
}

.link-block-5 {
  color: #fff;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.html-embed-6 {
  width: 20px;
  height: 20px;
}

.w-embed:before,
.w-embed:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.container-main {
  width: 100%;
  max-width: 960px;
  flex-direction: row;
  justify-content: space-between;
  align-self: auto;
  align-items: stretch;
  display: flex;
}

.section.section-custom-main {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: stretch;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  overflow: hidden;
  min-height: 70vh;
}

.div-main {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-sub {
  width: 100%;
  grid-column-gap: 26px;
  grid-row-gap: 50px;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  color: white;
  align-items: center;
  margin-top: 65px;
}

.div-text {
  font-size: 42px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}

.div-desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.div-button {
  width: 297px;
  height: 62px;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 4px #ffffff33;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.div-next-button {
  width: 297px;
  height: 62px;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 4px #ffffff33;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.div-footer {
  display: flex;
  justify-content: flex-end;
  padding: 50px 100px;
  text-align: center;

  .first-footer-button {
    width: 300px;
    height: 80px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1019607843);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    background: rgba(94, 105, 115, 0.168627451);
    color: white;
  }

  .third-footer-button {
    display: inline-flex;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1019607843);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    background: rgba(94, 105, 115, 0.168627451);
    color: white;
    max-height: 300px;
    max-width: 350px;
    position: absolute;
    bottom: 50px;
  }
}

.div-input-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
}

.org-input-field,
.channel-input-field {
  width: 417px;
  height: 62px;
  border-radius: 15px !important;
  background: #041a271a !important;
  box-shadow: 0px 0px 0px 4px #ffffff33 !important;
  padding: 15px !important;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: white !important;
}

.org-input-field::placeholder,
.channel-input-field::placeholder {
  color: white !important;
}

.org-name,
.create-channel {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.org-paragraph {
  font-size: 18px;
  font-weight: 400;
}

.success-icon {
  color: green;
  position: relative;
  right: 50px;
  margin-top: -10px;
}

.go-to-dashboard,
.go-to-orderers,
.go-to-smart-contracts {
  width: 297px;
  height: 62px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  font-size: 18px;
  font-weight: 600;
}

.go-to-dashboard:hover {
  background: #fff;
  background-color: #b0d51c !important;
  width: 297px;
  height: 62px;
  border-radius: 15px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  font-size: 18px;
  font-weight: 600;
}

.private-blockchain-text {
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}

.go-to-orderers:disabled,
.go-to-smart-contracts:disabled {
  background: #1a5d94;
}

@media screen and (max-width: 479px) {
  .section.section-custom-main {
    min-height: 50vh;
  }

  .swap-container {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .div-text {
    font-size: 22px;
    letter-spacing: normal;
  }

  .div-desc {
    font-size: 14px;
  }

  .div-input-text {
    font-size: 20px;
  }

  .org-input-field,
  .channel-input-field {
    width: auto;
    height: auto;
    font-size: 16px;
  }

  .image-edx-logo {
    height: 3rem;
  }

  .div-sub {
    // margin-top: auto;
    grid-row-gap: 25px;
  }

  .div-footer {
    padding-bottom: 0px;

    .third-footer-button {
      bottom: auto;
      font-size: 14px;
    }
  }
}

.invalid-feedback {
  display: block;
}

.first-arrow-container {
  position: relative;
  width: 7%;
  height: 2px;
  transform: rotate(90deg);
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.first-arrow-line {
  position: absolute;
  content: "";
  background-color: white;
  width: 0%;
  height: 100%;
  border-radius: 2px;
  animation: first-load 2.5s linear forwards;
  box-shadow: white 0px 2px 29px 0px;
  -webkit-animation: first-load 2.5s linear forwards;
}

.first-arrow-container.completed .first-arrow-line {
  background-color: white;
}

.first-arrow-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

@keyframes first-load {
  50% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

.first-down-arrow {
  width: 20px;
  height: 20px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  margin-top: -9px;
  transform: rotate(45deg);
  margin-left: 44px;
  animation: first-arrow 2s linear forwards;
  -webkit-animation: first-arrow 2s linear forwards;
}

@keyframes first-load {
  50% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

.second-arrow-container {
  position: relative;
  width: 7%;
  height: 2px;
  transform: rotate(90deg);
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.second-arrow-line {
  position: absolute;
  content: "";
  background-color: white;
  width: 0%;
  height: 100%;
  border-radius: 2px;
  animation: first-load 4s linear forwards 2.5s;
  box-shadow: white 0px 2px 29px 0px;
  -webkit-animation: first-load 4s linear forwards 2.5s;
}

.second-arrow-container.completed .second-arrow-line {
  background-color: white;
}

.second-arrow-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

@keyframes second-load {
  50% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

.second-down-arrow {
  width: 20px;
  height: 20px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  margin-top: -9px;
  transform: rotate(45deg);
  margin-left: 44px;
}