@import '../common/variables';
.api-wrapper {
  border: 1px solid #dadce0;
  border-radius: 10px;
  display: block;
  content: ' ';
  padding: 20px;
  position: relative;

  .paragrah-content {
    min-height: 69px;
  }
  .points-wrapper {
    height: 110px;
    overflow: hidden;
    &.available-on {
      height: 110px;
    }
  }

  @media screen and (max-width: 1399px) {
    .points-wrapper {
      height: 110px;
      overflow: hidden;
      &.available-on {
        height: 145px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    margin-bottom: 1.5rem;
    .paragrah-content {
      min-height: 35px;
    }
    .points-wrapper {
      height: 84px;
      overflow: hidden;
      &.available-on {
        height: 100px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .points-wrapper {
      height: 84px;
      overflow: hidden;
      &.available-on {
        height: 145px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .points-wrapper {
      height: auto;
      overflow: hidden;
      &.available-on {
        height: auto;
      }
    }
  }
}
.title-wrapper {
  display: flex;
  .block-1 {
    width: 80px;
    height: 80px;
    display: inline-flex;
    padding: 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 5px;
    margin-right: 15px;
    img {
      display: block;
      max-width: 50px;
    }
  }
  .block-2 {
    width: calc(100% - 125px);
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.content {
  margin: 20px 0;
}

.btn-block {
  padding: 10px 0;
}
.custum-badge {
  background-color: $forth;
  padding: 5px 10px;
  color: $white;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: capitalize;
  border-radius: 50px;
}
.available-list {
  display: flex;
  flex-wrap: wrap;

  .available-bedge {
    background-color: $fifth;
    padding: 4px 12px;
    border-radius: 3px;
    margin-right: 6px;
    margin-bottom: 6px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.3s;
    .icon {
      margin-right: 5px;
    }
    p {
      font-size: 12px;
      line-height: 22px;
      color: $secondary;
    }
    &:hover {
      background-color: $secondary;
      transition: all ease 0.3s;
      p {
        color: $white;
      }
    }
  }
}

.readmore {
  text-decoration: none;
  color: $secondary;
  &:hover {
    color: $primary;
  }
}
