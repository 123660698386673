@import '../common/variables';
.allnetwork-wrapper {
  background-color: $white;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  .title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.listofnetwork {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  li {
    width: 100%;
    .title-drp {
      padding: 26px 10px;
      display: inline-flex;
      width: 100%;
      border-bottom: 1px solid $sixth;
      justify-content: space-between;
      .block-2 {
        width: 30px;
      }
      .block-1 {
        max-width: calc(100% - 30px);
      }
      &:hover {
        background-color: #f5f6f8;
      }
    }
  }

  .detail-li {
    display: inline-flex;
    .bl_cmn {
      padding: 15px 10px;
      border-bottom: 1px solid $sixth;
      margin-right: 5px;
      &:last-child {
        margin-right: 0px;
      }
      flex: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      &:hover {
        background-color: #f5f6f8;
      }
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
