.rc-pagination-item{
    border:none;
    font-size:14px;
    font-weight: 600 !important;
    color: #90a1b8;
    background-color: transparent;
    margin-right: 0!important;
}
.rc-pagination-item-active{
    color: #073d83;
}.rc-pagination{
    color: #90a1b8;

}.rc-pagination-jump-prev button:after{
    color: #90a1b8;
}
.rc-pagination-jump-next button:after{
    color: #90a1b8;
}
.rc-pagination-item-link{
    border:none !important;
}
.rc-pagination-next button:after,.rc-pagination-prev button:after{
    line-height: 22px;
}
.rc-pagination-item a{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.rc-pagination-prev{
height: -1px;
}
.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {
    margin-right: 0px !important;
}