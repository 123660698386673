@import '../common/variables';
/*banner portion start*/
.home-wrapper {
  min-height: 80vh;
  .banner {
    height: 270px;
    width: 100%;
    margin: 50px 0;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    .custom-banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      position: absolute;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      .content-wrapper {
        padding: 50px;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        .banner-title {
          font-size: 44px;
          line-height: 52px;
          margin-bottom: 20px;
        }
        .btn-bl {
          width: 194px;
          height: 37px;
          padding: unset;
        }
        .banner-paragraph {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}
/*banner portion end*/
.custom-stepper-popup {
  .stepper-link-wrapper {
    position: absolute;
    top: -90px;
    width: 100%;
    left: 0;
  }

  .custom-card-wrapper {
    height: 500px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 20px;
    .tab-block {
      display: flex;
      .left-block {
        padding: 0px 30px 20px 20px;
        display: inline-flex;
        width: calc(100% - 306px);
        // background-color: red;
        flex-direction: column;

        .detail-block {
          margin-top: 14px;
        }
        .flex-wrap-bl {
          justify-content: space-between;
          display: flex;
          flex-direction: column;
        }
      }
      .tab-heading {
        margin-bottom: 20px;
      }
      .right-block {
        width: 306px;
        background-color: $fifth;
        border-radius: 10px;
        .flex-bl {
          height: 100%;
          padding: 20px 30px;
        }
      }
    }
  }
}
/*radio button design start*/
label {
  width: 100%;
}
.card-input-element {
  display: none;
}
.card-input {
  margin: 0px;
  padding: 20px;
  width: 100%;
  border: 1px solid $sixth;
  border-radius: 5px;
}

.card-input:hover {
  cursor: pointer;
}
.custom-radio-btn {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  border: 1px solid $sixth;
  &:after {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    display: none;
    content: ' ';
    transform: translate(-50%, -50%);
    background-color: $secondary;
    border-radius: 50%;
  }
}
.card-input-element:checked + .card-input {
  border: 1px solid $secondary;
  width: 100%;
  .custom-radio-btn {
    border: 1px solid $secondary;
    &:after {
      display: block;
    }
  }
}
.card-input-element:disabled + .card-input {
  opacity: 0.5;
}
/*radio button design end*/
/*tab -1 start*/
.out-wrap {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  .sm-wrap {
    display: inline-flex;
    width: calc(25% - 10px);
  }
}

.ct-link {
  margin-top: 20px;
  a {
    color: $forth;
  }
}
/*tab -1 end*/
/*tab-3*/
.select-wrap {
  width: 100%;
  .pg {
    margin-bottom: 10px;
    .card-input-element:checked + .card-input {
      border: 1px solid $primary;
      width: 100%;
      .custom-radio-btn {
        border: 1px solid $primary;
        &:after {
          display: block;
        }
      }
    }
    .bl-b-1 {
      width: 45%;
    }
    .bl-b-2 {
      width: 30%;
    }
    .bl-b-3 {
      width: 25%;
    }
    .bl-b {
      display: inline-flex;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
  }
  .wrapper-bl-block-first {
    width: 80%;
  }
}

/*tab-4 start*/
.tab-4 {
  .sub-tl {
    margin-bottom: 20px;
  }
} /*tab-4 end*/

/*stepper link block start*/
.list-step {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  li {
    display: inline-flex;
    color: $sixth;
    align-items: center;
    .icon-bl {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.5);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }
    .title {
      padding-left: 10px;
      p {
        font-size: 14px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .border-block {
      width: 60px;
      margin-left: 15px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      align-items: center;
    }
    &:first-child {
      .border-block {
        display: none;
      }
    }
    &.active {
      .icon-bl {
        border: 1px solid rgba(255, 255, 255, 1);

        background-color: #ffffff;
        p {
          color: #073d83;
        }
      }
      .title {
        p {
          color: rgba(255, 255, 255, 1);
        }
      }
      .border-block {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
/*stepper link block end*/
@media screen and (min-width: 1199px) and (max-height: 650px) {
  .custom-stepper-popup {
    .stepper-link-wrapper {
      position: relative;
      top: 0px;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-stepper-popup {
    .stepper-link-wrapper {
      position: relative;
      top: 0px;
    }
    max-width: calc(100% - 30px);
    height: 500px;
  }
}

@media screen and (max-width: 992px) {
  .custom-stepper-popup {
    .stepper-link-wrapper {
      position: relative;
      top: 0px;
    }
  }
  .home-wrapper {
    .banner {
      .content {
        .content-wrapper {
          height: 100%;
          width: 100%;
          padding: 20px;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: flex-start;
          .banner-title {
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 20px;
          }
          .banner-paragraph {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .custom-stepper-popup {
    .custom-card-wrapper {
      .tab-block {
        display: block;
        .left-block {
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
        }
        .right-block {
          width: 100%;
        }
      }
      height: auto;
    }
  }
  .select-wrap {
    width: 100%;
    .pg {
      .bl-b-1 {
        width: 140px;
      }
    }
  }
  .list-step {
    li {
      .title {
        padding-left: 10px;
        display: none;
      }
    }
  }
  .modal-close {
    top: 15px;
    right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .select-wrap {
    width: 100%;
    .pg {
      .bl-b {
        padding: 10px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 574px) {
  .custom-stepper-popup {
    .custom-card-wrapper {
      .tab-block {
        display: block;
        .left-block {
          width: 100%;
        }
        .right-block {
          width: 100%;
        }
      }
    }
  }
  .out-wrap {
    flex-wrap: wrap;
    .sm-wrap {
      width: calc(50% - 10px);
      margin-bottom: 10px;
    }
  }
  .list-step {
    li {
      .border-block {
        width: 15px;
        margin-left: 5px;
      }
    }
  }
  .select-wrap {
    width: 100%;
    .pg {
      .bl-b {
        padding: 10px;
        width: 100%;
      }
      .custom-radio-btn {
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 376px) {
  .out-wrap {
    flex-wrap: wrap;
    .sm-wrap {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
