.loading {
  color: #969799;
  text-align: center;
  padding-top: 5%;
}

.custom-tooltip {
  width: 100%;
  border: 1px solid #969799;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
}
