@import '../common/variables';
.backdrop-block {
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  .title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.documentation-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  // height: 155px;
  overflow-y: auto;
  .element {
    .block-1 {
      width: 100%;
      @media (max-width: 991px) {
        padding: 10px;
      }
    }
    &:hover {
      background-color: #f5f6f8;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    cursor: pointer;
    .description {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
  li {
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    .first-title {
      margin-bottom: 3px;
    }
    border-bottom: 1px solid #dadce0;
    li {
      padding: 10px;
      width: calc(50% - 5px);
      .first-title {
        margin-bottom: 3px;
      }
      border-bottom: 1px solid #dadce0;
      &:hover {
        background-color: #f5f6f8;
      }
    }
  }
}
