@import "../../../src/styles/common/variables";
.sidebar-toggle-btn {
  border-radius: 50px;

  color: white;
  cursor: pointer;
  font-size: 20px;
}
.dashboard-layout {
  .sidebar-wrapper {
    position: fixed;
    width: 280px;
    height: calc(100vh - 59px);
    top: 59px;
    display: block;
    left: 0;
    background: linear-gradient(197deg, #1967a9 0%, #0a1832 100%);
    padding: 0px 0px;
    transition: all ease 0.5s;
    .menutoggle {
      display: none;
    }
    @media screen and (max-width: 1199px) {
      left: -280px;
      z-index: 1;
      .menutoggle {
        z-index: 2;
        position: absolute;
        right: -15px;
        padding: 5px 10px;
        border-radius: 15px;
        background: linear-gradient(197deg, #1967a9 0%, #0a1832 100%);
        color: #ffffff;
        font-size: 10px;
      }
    }
    &.active {
      @media screen and (max-width: 1199px) {
        left: 0px;
      }
    }

    .page-link_sidebar {
      padding: 0;
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: $primary_Font;
        .icon {
          margin-right: 10px;
          width: 14px;
        }
        padding-top: 18px;
        padding-bottom: 18px;
        background-color: transparent;
        color: #ffffff;
        &:hover,
        &:focus {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 2px;
          backdrop-filter: blur(3px);
          -webkit-backdrop-filter: blur(3px);
        }
        &:active,
        &.active {
          background: transparent
            linear-gradient(259deg, #1967a9 0%, #0a1832 100%);
          color: #ffffff;
        }
      }
    }

    .c-px-20 {
      padding-right: 20px;
      padding-left: 20px;
    }
    .title {
      font-size: 11px;
      line-height: 13px;
      padding: 10px 0;
      font-weight: 700;
      color: $fifth;
      margin-bottom: 0;
    }
  }
  .admin-content-wrapper {
    background-color: #f5f6f8;
    width: calc(100% - 280px);
    position: fixed;
    top: 59px;
    margin-left: 280px;
    display: block;
    height: calc(100vh - 59px);
    padding: 20px;
    overflow-y: auto;
    @media screen and (max-width: 1199px) {
      margin-left: 0;
      width: 100%;
    }
  }
}
.dashboard-content-wrapper {
  min-height: calc(100% - 24px);
  .api-bl {
    background: transparent linear-gradient(90deg, #073d83 0%, #007de4 100%);
    border-radius: 10px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    width: 100%;
    height: 280px;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .api-counter {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  .detail-counter {
    display: flex;
    align-items: center;
    height: 130px;
    border-radius: 10px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    padding: 20px;
    background: transparent linear-gradient(90deg, #073d83 0%, #007de4 100%);
    .counter-wrp {
      display: inline-flex;
      margin-right: 20px;
      .font-50 {
        line-height: 60px;
      }
    }
    margin-bottom: 20px;
    &.second {
      margin-bottom: 0;
      @media screen and (max-width: 1199px) {
        margin-bottom: 20px;
      }
    }
  }
}
.backdrop-block {
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  .title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.blockchain-list-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    padding: 10px;
    width: calc(50% - 5px);
    .first-title {
      margin-bottom: 3px;
      word-break: break-all;
    }
    border-bottom: 1px solid #dadce0;
    &:hover {
      background-color: #f5f6f8;
    }
  }

  &.with-three-element {
    li {
      width: calc(33.33% - 5px);
      align-items: center;
      height: 80px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.channel-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 289px;
  overflow-y: auto;
  li {
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    .first-title {
      margin-bottom: 3px;
    }
    border-bottom: 1px solid #dadce0;
    li {
      padding: 10px;
      width: calc(50% - 5px);
      .first-title {
        margin-bottom: 3px;
      }
      border-bottom: 1px solid #dadce0;
      &:hover {
        background-color: #f5f6f8;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    border: 1px solid #dadce0;
    border-radius: 10px;
    overflow: hidden;
    min-width: 70px;
    button {
      font-size: 12px;
      color: $primary;
      &:hover,
      &:focus {
        background-color: #f5f6f8;
      }
    }
  }
}
.user-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 289px;
  overflow-y: auto;
  li {
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    .first-title {
      margin-bottom: 3px;
    }
    border-bottom: 1px solid #dadce0;
  }
  .block-1 {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 30px);
    .bl_wrap1 {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .bl_wrap2 {
      max-width: calc(100% - 50px);
    }
  }
  .block-2 {
    width: 30px;
    display: inline-flex;
    justify-content: flex-end;
  }
  .dropdown-menu {
    padding: 0;
    border: 1px solid #dadce0;
    border-radius: 10px;
    min-width: 70px;
    overflow: hidden;
    button {
      font-size: 12px;
      color: $primary;
      &:hover,
      &:focus {
        background-color: #f5f6f8;
      }
    }
  }
}
.dropdown-menu {
  padding: 0;
  border: 1px solid$sixth;
  border-radius: 10px;
  overflow: hidden;
  min-width: 70px;
  button {
    font-size: 12px;
    color: $primary;
    &:hover,
    &:focus {
      background-color: #f5f6f8;
      color: $primary !important;
    }
  }
}
.link-wrapper {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.ct-table {
  padding: 0 10px;
  overflow-y: auto;
  max-height: 317px;
  th {
    font-size: 11px;
    font-family: $primary_Font;
    color: $primary;
    font-weight: 900;
    line-height: 13px;
  }
  td {
    font-size: 14px;
    font-family: $primary_Font;
    color: $primary;
    line-height: 17px;
    vertical-align: middle;
  }
  th,
  td {
    padding: 15px 18px !important;
  }
  .table > :not(:first-child) {
    border-top: 1px solid #dadce0;
  }
  .action-th {
    min-width: 170px;
    width: 170px;
  }

  .custom-primary {
    &.key {
      font-size: 10px;
      line-height: 12px;
      padding: 5px 10px;
      border-radius: 3px;
      opacity: 0.8;
      &.dis {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .table {
    margin-bottom: 0px;
  }
}

#activity-log{
  overflow: hidden;
}
.drpmenu {
  padding: 20px !important;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 500px;
  background-color: #ffffff !important;
  border: 1px solid #dadce0;
  border-radius: 10px;
  display: none;
  z-index: 9999;
  &.active {
    display: block;
  }
}
.my-api-wrapper {
  table {
    th:last-child,
    td:last-child {
      width: 110px;
    }
  }
}
.input-wrp {
  position: relative;
  input {
    padding-right: 50px;
  }
  .icons {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-0, -50%);
    color: $forth;
    cursor: pointer;
  }
}

.prg-wrap {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f5f6f8;
  border-radius: 5px;
  padding: 20px;
  align-items: center;
}
.progress-bar-block {
  width: 140px;
  @media screen and (max-width: 1599px) {
    width: 100px;
  }
}
.bl_content {
  width: calc(100% - 140px);
  padding-left: 20px;
  @media screen and (max-width: 1599px) {
    width: calc(100% - 100px);
    padding-left: 10px;
  }
}

.badge-wrapper {
  background-color: $forth;
  padding: 2px 10px 3px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  color: $white;
  &.dark {
    background-color: $secondary;
  }
  &.light {
    background-color: #c1c1c1;
    cursor: not-allowed;
  }
}
.select-map {
  background-color: #f5f6f8;
  padding: 4px;
  border-radius: 4px;
}
.user-img-wrp {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  min-width: 16px;
  min-height: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.icon-bl {
  color: #dadce0;
  cursor: pointer;
  &:hover {
    color: #868686;
  }
}
.css-b62m3t-container {
}
.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 50px !important;
}

.peer-drop-down{
  .css-1s2u09g-control,
.css-1pahdxg-control {
  width: 100px;
  min-height: 0px !important;
}
}

.css-1s2u09g-control {
  border-radius: 10px !important;
  border: 1px solid #dadce0 !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #1e2835 !important;
  border-radius: 10px !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  svg {
    width: 15px !important;
  }
}