@import '../common/variables';

.active-nav-item {
  background-color: $seventh;
  color: $white;
}
.notification-count {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 22px;
  height: 22px;
  background-color: #12406e;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-wrapper {
  // position: sticky;
  top: 0;
  z-index: 55;
  .navbar {
    background-color: $primary;
    padding: 0 !important;

    .navbar-nav {
      a {
        color: $white;
        font-size: 14px;
        line-height: 17px;
        padding: 21px 20px !important;
        &:hover,
        &:active,
        &:focus {
          background-color: $seventh;
          color: $white;
        }
      }
    }
    .navbar-toggler {
      padding: 3px 7px;
    }
  }
  .navbar-toggler-icon {
    width: 1.3em;
    height: 1.3em;
  }
  .dropdown {
    &::marker {
      display: none !important;
    }
  }
  .menus-wrapper {
    display: inline-flex;
    align-items: center;
    a {
      padding: 18px !important;
    }
  }
  .notification-link {
    a {
      color: $white;
      font-size: 20px;
      line-height: 24px;
      // padding: 22px 20px !important;
      &:hover,
      &:active,
      &:focus {
        background-color: #000000;
        color: $white;
      }
    }
  }
  .user-dropdown {
    a {
      padding: 10px 16px !important;
    }
    .img-wrapper {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.app-dropdown {
  .dropdown-menu {
    border: 1px solid $sixth;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    z-index: 9999999;
  }
  .dropdown-item {
    color: #000000;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $white;
    }
  }
  .menu-list {
    padding: 10px;
    width: 398px;
    height: 300px;
    overflow: hidden;
    margin-top: 10px;
  }
  .projects-section {
    overflow: auto;
    padding-bottom: 57px;
    height: 100%;
    -ms-overflow-style: scroll; // IE 10+
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .project-list {
    white-space: nowrap;
    flex-wrap: wrap;
    display: flex;
    .project {
      width: 86px;
      height: 87px;
      text-align: center;
      padding: 12px 0;
      margin: 0 4px 5px;
      pointer-events: visible;
      align-items: flex-start;
      cursor: pointer;
      &:hover {
        background-color: #e8f0fe;
        border-radius: 5px;
      }
      p {
        padding-top: 5px;
      }
      .app-img {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: 100%;
        }
      }
      &.disable {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  .more-button {
    text-align: center;
    font-weight: 600;
    transition: all ease 0.3s;
    color: $secondary;
    height: 57px;
    background-color: #fff;
    border: solid #dadce0;
    border-width: 1px 0 0;
    &:hover {
      color: $secondary;
      transition: all ease 0.3s;
      background-color: #e8f0fe;
    }
  }
}
