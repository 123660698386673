/* src/components/StepperForm.css */
.stepper-form {
  max-width: 600px;
  margin: auto;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  flex: 1;
  text-align: center;
  position: relative;
}

.step-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.line {
  height: 2px;
  top: -20px;
  position: relative;
  right: -28px;
  background-color: #1967a9;
}

.step.active .step-icon {
  background-color: #073d83;
  color: white;
}

.step-content {
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  width: 70%;
}

/* Animation */

.step-content.active {
  transform: translateY(0);
}

.step .active {
  color: #047f3b !important;
}
