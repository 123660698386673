@import '../common/variables';
.footer-wrapper {
  margin: 25px 0;
  padding: 0px 0;
  .page-link-list {
    display: flex;
    justify-content: flex-end;
    li {
      &:last-child {
        a {
          padding-right: 0px;
        }
      }
    }
    a {
      font-size: 14px;
      color: $ninth;
      padding: 10px;
      text-decoration: none;
      &:hover,
      &:focus {
        color: $secondary;
      }
    }
  }
}
