@import '../common/variables';
.api-detail-wrapper {
  &.explore-details {
    .switch-block {
      padding: 22px 0 34px 0;
      display: flex;
      align-items: center;
      p {
        line-height: 17px;
        font-weight: 700;
      }
      .switch-container {
        display: inline-flex;
        margin: 0 10px 0 13px;
      }
    }
  }

  .http-status-wrapper {
    .inner-common-block {
      .status-header {
        &.common-pad {
          &.with-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 17px 30px;
            .bl-1 {
              width: calc(100% - 150px);
            }
          }
        }
      }
    }
    .code-list {
      li {
        .block-1 {
          display: inline-flex;
          width: 42px;
          border-right: 1px solid #dadce0;
        }
        .block-2 {
          display: inline-flex;
          width: calc(100% - 42px);
          padding-left: 10px;
        }
      }
    }
  }
}
.form-block {
  .form-control {
    position: relative;
    padding-right: 50px;
  }
  .form-control:hover{
    cursor: pointer;
    background-color: #00bef320 ;
  }
  .icon-absolute {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;
  }
}

.api-explorer-status-wrapper {
  .datalist {
    .point-list {
      li {
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.url-block-inner {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  height: 57px;
  margin: 20px 0;
  .sp-1 {
    width: 97px;
    height: 100%;
    padding: 0 30px;
    background-color: $sixth;
  }
  .sp-2 {
    width: calc(100% - 57px);
    padding: 0 30px;
    border: 1px solid $sixth;
    border-radius: 0 10px 10px 0;
    p {
      cursor: pointer;
    }
  }
}
.custom-card-block {
  border: 1px solid $sixth;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.era-wrapper {
  background-color: #f8f8f8;
}

.accordion-button {
  font-size: 14px !important;
  color: $primary !important;
  font-weight: 700 !important;
  background-color: transparent !important;
  padding: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  &::after {
    flex-shrink: 0;
    width: 15px !important;
    height: 15px !important;
    margin-left: auto;
    content: '';
    background-image: url('../../assets/Images/arrow.svg') !important;
    background-repeat: no-repeat;
    background-size: 15px !important;
    transition: transform 0.2s ease-in-out;
  }
}
.accordion-item {
  background-color: transparent !important;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
}

.execute-button {
  padding: 20px 0;
  border-top: 1px solid $sixth;
  margin-top: 10px;
}
.code-links {
  display: flex;
  align-items: center;
  li {
    margin-right: 30px;
    p {
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
    &.active {
      p {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.st-sticky {
  position: sticky;
  top: 209px;
}
.stickey-149 {
  top: 149px !important;
}