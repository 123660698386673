.border-bottom-radius-10{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.notification-container {
    .notification-message {
        cursor: default;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.back-button {
    background: none;
}
.unread-msg{
    background-color: #F5F6F8;
}
.notification-info {
    display: flex;
    align-items: center;
    width: 100%;
}

  
.notification-text {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.align-notification {
    align-items: center;
}
.scrollbar-notification {
    max-height: 60vh !important;
    overflow: hidden;
}

.trackVertical {
    right: 0;
    top: 0;
    height: 100% !important;
    width: 2px !important;
}
.trackVertical div {
    background-color: #073d83 !important;
    width: 2px !important;
}

.close-icon {
    cursor: pointer !important;
    // font-weight: 700 !important;
}

.opacity-1{
    opacity: 1 !important;
}