@import '../common/variables';
.backdrop-block {
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  .title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.connectblock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 216px;
  overflow-y: auto;
  li {
    padding: 10px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    .first-title {
      margin-bottom: 3px;
    }
    border-bottom: 1px solid #dadce0;
    li {
      padding: 10px;
      width: calc(50% - 5px);
      .first-title {
        margin-bottom: 3px;
      }
      border-bottom: 1px solid #dadce0;
      &:hover {
        background-color: #f5f6f8;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    border: 1px solid #dadce0;
    border-radius: 10px;
    min-width: 70px;
    button {
      font-size: 12px;
      color: $primary;
    }
  }
}
