.peer-node-overview-wrapper{
    .peer-node-list{
        li{display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #dadce0;
            .first-peer-title ,.second-peer-title {
                width: 40%;
                display: inline-flex;
                align-items: center;
            }  
            .third-peer-title{
                width: 20%;
                text-align: end;
                justify-content: flex-end;
            }  
        }
    }
}
.cloud-configuration-wrapper{
    .cloud-list{
        li{ 
            display: flex;
        align-items: center;
        padding: 26px 10px ;
        border-bottom: 1px solid #dadce0;
        @media screen and (max-width: 767px) { 
            display:block;
            }
        .first-peer-title {
            width: calc(100% - 350px);
            display: inline-flex;
            flex-direction: column;
            @media screen and (max-width: 767px) { 
            width: 100%;
            }
        }  
        .second-peer-title {
            width: 350px;
            display: inline-flex;
            text-align: end;
            justify-content: flex-end;
            align-items: center;
            @media screen and (max-width: 767px) { 
                width: 100%;
                margin-top: 15px;
                justify-content: space-between;
                }
        }
        .text-bl{
            margin-right: 20px;
        }
       
    }}
   
}