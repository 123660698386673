@import '../common/variables';
.api-detail-wrapper {
  .breadcum-wrapper {
    padding: 20px 0 16px;
    ol {
      margin-bottom: 0;
    }
    a {
      color: $ninth;
      text-decoration: none;
      &:hover {
        color: $secondary;
      }
    }
    .breadcrumb-item {
      font-size: 12px;
      line-height: 14px;
      &.active {
        color: #0d0f12;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
    }
  }
}

.custom-stick {
  position: sticky;
  top: 60px;
  background-color: #ffffff;
  z-index: 5;
  transition: all 0.1s;
}
.top-0 {
  top: 0px !important;
  transition: all 0.1s;
}
.stickey {
  position: sticky;
  transition: all 0.1s;
}

.api-details-header {
  padding-bottom: 50px;
  .api-detail-btn-list {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
      width: 184px;
      margin-left: 10px;
      @media screen and (max-width: 1199px) {
        width: auto;
        padding: 10px 20px;
      }

      @media screen and (max-width: 1199px) {
        margin-left: 0px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: 991px) {
      justify-content: flex-start;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
}

.details-wrapper {
  .image-wrapper {
    width: 100%;
    height: 306px;
    border-radius: 5px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 205px;
    &.is-stickey {
      top: 149px;
    }
    @media screen and (max-width: 1199px) {
      height: 250px;
      top: 210px;
    }
    @media screen and (max-width: 991px) {
      height: 200px;
      top: 245px;
      &.is-stickey {
        top: 188px;
      }
    }
    // @media screen and (max-width: 991px) {
    //   height: 200px;
    //   top: 245px;
    // }
    @media screen and (max-width: 767px) {
      height: 300px;
      width: 300px;
      margin: 0 auto;
    }
    .custum-badge {
      background-color: #007de4;
      padding: 5px 10px;
      color: #ffffff;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: auto;
      height: auto;
      width: fit-content;
      height: fit-content;
      left: 50%;
      transform: translate(-50%, 0);
      text-transform: capitalize;
      border-radius: 50px;
      top: auto;
      bottom: -12px;
    }
  }
  .point-list {
    li {
      margin-bottom: 7px;
    }
  }
}

.point-list {
  list-style-type: none;
  margin-top: 5px;
  li {
    margin-bottom: 5px;
    padding-left: 25px;
    position: relative;
  }
}
.backarrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -50px;
  display: block;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease 0.3s;
  svg {
    path {
      transition: all ease 0.3s;
    }
  }
  &:hover {
    background-color: $primary;
    svg {
      path {
        fill: $white;
        transition: all ease 0.3s;
      }
    }
  }
  @media screen and (max-width: 767px) {
    position: relative;
    top: auto;
    transform: none;
    left: 0;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.base-url-block {
  .base-url-inner-block {
    margin: 50px 0;
    display: flex;
    background-color: $seventh;
    border-radius: 10px;
    overflow: hidden;
    @media screen and (max-width: 574px) {
      display: block;
    }
    .block-1 {
      width: 224px;
      display: inline-flex;
      align-items: center;
      background-color: #1e2835;
      padding: 20px 30px;
      @media screen and (max-width: 574px) {
        width: 100%;
      }
    }
    .block-2 {
      width: calc(100% - 224px);
      background-color: #16202d;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      .copy-link {
        color: #85d996;
        text-decoration: none;
        transition: all ease 0.3s;
        &:hover {
          color: $white;
          transition: all ease 0.3s;
        }
      }
      @media screen and (max-width: 574px) {
        width: 100%;
        display: block;
        .copy-link {
          margin-top: 10px;

          display: block;
          width: fit-content;
        }
      }
    }
  }
}
.common-pad {
  margin-bottom: 50px;
}
.http-status-wrapper {
  .inner-common-block {
    border-radius: 10px;
    overflow: hidden;
    .status-header {
      background-color: $sixth;
    }
    .common-pad {
      padding: 20px 30px;
    }
  }
  .code-list {
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      width: 100%;
      display: flex;
      padding: 15px 0;
      .block-1 {
        display: inline-flex;
        width: 120px;
        @media screen and (max-width: 767px) {
          width: 70px;
        }
      }
      .block-2 {
        display: inline-flex;
        width: calc(100% - 70px);
      }
      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
.error-block {
  .error-header {
    background-color: #1e2835;
  }
  .code-list {
    background-color: #16202d;
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 5%);
      width: 100%;
      display: flex;
      padding: 15px 0;
      background-color: #16202d;
    }
  }
}

.divider {
  margin: 15px 0 !important;
}

/*toggle switch css*/
// .switch-container {
//   // position: absolute;
//   // top: 50%;
//   // left: 50%;
//   // -webkit-transform: translate3d(-50%, -50%, 0);
//   // transform: translate3d(-50%, -50%, 0);
// }

input[type='checkbox'].switch {
  position: absolute;
  opacity: 0;
}

input[type='checkbox'].switch + div {
  vertical-align: middle;
  width: 50px;
  height: 25px;
  border-radius: 999px;
  background-color: $primary;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type='checkbox'].switch + div span {
  position: absolute;
  font-size: 14px;
  color: white;
  margin-top: 12px;
}

input[type='checkbox'].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type='checkbox'].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type='checkbox'].switch:checked + div {
  width: 50px;
  background-position: 0 0;
  background-color: $primary;
}

input[type='checkbox'].switch + div {
  width: 50px;
  height: 25px;
}

input[type='checkbox'].switch:checked + div {
  background-color: #85d996;
}

input[type='checkbox'].switch + div > div {
  float: left;
  width: 19px;
  height: 19px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 3px;
  margin-left: 2px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type='checkbox'].bigswitch.switch + div > div {
  width: 19px;
  height: 19px;
  margin-top: 2px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
}

.form-control:disabled{
  background-color: white;
}