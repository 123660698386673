@import '../common/variables';
.cursor-pointer {
  cursor: pointer;
}
.table-wrapper {
  height: calc(100vh - 256px);
}
.table-search-wrap {
  input {
    border: 0px !important;
  }
}
.list-title {
  margin-top: 50px;
  margin-bottom: 30px;
}

.table-wrapper {
  border: 1px solid var(---dadce0-border);
  background: #ffffff;
  border: 1px solid $sixth;
  border-radius: 10px;
  overflow: hidden;
  .table-responsive {
    // height: calc(100% - 66px);
    height: calc(100% - 49px);
    max-height: calc(100% - 49px);
    &::-webkit-scrollbar {
      height: 2px;
      width: 4px;
    }
  }

  .table-drp {
    width: 110px;
    min-width: 130px;
    color: var(---073d83-primary);
    padding: 0px 10px;
    // left: 50% !important;
    transform: translateX(0) !important;
    // top: 25px !important;

    .dropdown-item {
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      color: $secondary;
      text-transform: uppercase;
      padding: unset;
      padding-top: 6px;
      padding-bottom: 6px;
      &:hover {
        background: #f8f8f8;
      }
    }
  }
  thead {
    background: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  tbody {
    border-top: 0 !important;
  }
  th,
  td {
    padding: 20px 16px !important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;
    color: $primary;
  }
  td {
    &:nth-child(4) {
      width: 30%;
      word-break: break-all;
    }
    &:nth-child(6) {
      width: 150px;
      word-break: break-all;
    }
  }
  th {
    font-weight: 700;
    text-transform: capitalize;
  }

  @media screen and (max-width: 991px) {
    td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        min-width: 250px;
        word-break: break-all;
      }
      &:nth-child(4) {
        min-width: 300px;
        word-break: break-all;
      }
      &:nth-child(6) {
        width: 150px;
        word-break: break-all;
      }
    }
  }
}
/*pop up css */
.user-management-popup {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .user-form {
    height: 450px;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
    }
    .form-control,
    .form-select {
      border-radius: 5px !important;
      // margin-bottom: 1.5rem;
    }
    .file-input-wrapper {
      margin-bottom: 1.5rem;
    }

    .form-wrapper {
      margin-top: 20px;
      .form-label {
        margin-bottom: 0px !important;
      }
      .file-input-wrapper {
        .form-control {
          margin-bottom: 0rem;
          padding-right: 99px;
          text-align: left;
        }
        .file-btn {
          top: 50%;
          right: 15px;
          transform: translate(0, -50%);
          position: absolute;
        }
      }
    }
    input[type='checkbox'].switch + div {
      background-color: $sixth;
    }
    input[type='checkbox'].switch:checked + div {
      // background-color: #85d996;
      background-color: $secondary;
    }
  }
  .switch-block {
    padding: 22px 0 34px 0;
    display: inline-flex;
    align-items: center;
    p {
      line-height: 17px;
      // font-weight: 700;
    }
    .switch-container {
      display: inline-flex;
      margin: 0 10px 0 13px;
    }
  }
  @media screen and (max-width: 991px) {
    .user-form {
      height: 100%;
    }
  }
}
// kyc country select height
.kycselect__menu-list {
  max-height: 150px !important;
}

.img-wrap-bl {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.text-bl {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*checkboc css  start*/

.check-list {
  display: flex;
  li {
    margin-right: 20px;
  }
}

label {
  width: 100%;
}
.card-input-check-element {
  display: none;
  &:checked {
    + {
      .card-input-check {
        // border: 1px solid $secondary;
        background: #f8f8f8;
        .custom-check-btn {
          border: 1px solid $secondary;
          background-color: $secondary;
          .checkmark-bl {
            display: block;
          }
        }
      }
    }
  }
  &:disabled {
    + {
      .card-input-check {
        opacity: 0.5;
      }
    }
  }
}
.card-input-check {
  margin: 0px;
  padding: 10px;
  border: 1px solid $sixth;
  border-radius: 10px;
  height: 160px;
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}
.custom-check-btn {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 5px;
  border: 1px solid $sixth;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
}
.checkmark-bl {
  display: none;
  font-size: 12px;
  line-height: 12px;
}

@media screen and (max-width: 1199px) {
  .card-input-check {
    width: 100%;
  }
}
/*checkboc css end*/

/*gender - wrapper*/
.gender-wrapper {
  display: flex;
  justify-content: space-between;
  .pg {
    .card-input {
      padding: 0.375rem 0.75rem;
      height: 50px;
      display: inline-flex;
    }
    .bl-b {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    .pg {
      margin-right: 1.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    .pg {
      margin-bottom: 1.5rem;
    }
  }
}
